function StreamRig() {
    return <>
    <h2 id="stream-rig-section">Stream Rig</h2>
        <div className="section stream-rig-section" >
            <div className="section-content">
                <div className="text-blurb">
                    Coming soon!
                </div>
            </div>
        </div>
    </>;
}
export default StreamRig;